.App {
  text-align: center;
  height: 100%;
}

.App-logo{
  max-width: 50vw;
  display: block;
  padding: 2vh 0 4vh 0;
  margin: 0 auto;
  min-width: 20vw;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content{
  background: url('background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  align-items: center;
}

.blur {
  background: rgba(0, 0, 25, 0.6);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  height: 100vh;
}

.content-body{
  color: white;
  font-weight: bold;
  position: absolute;
  top: 0;
  z-index: 9999;
  text-align: center;
  display: grid;
  grid-template-rows: 1fr 1fr 3fr;
  height: 80vh;
  width: 100%;
}

.content-body span{
  font-size: 1.3em;
    font-weight: 300;
    display: block;
    text-align: justify;
    line-break: auto;
    line-height: 1.4em;
    margin: 0 auto;
    padding: 4vh;
}

.animation { 
  margin-top: 20px;
}